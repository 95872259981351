<template>
	<div class="inspection-Detail" v-loading="initLoad">
		<div class="inspection-Detail-left">
			<template v-if="inspectionLocation.length === 1">
				<div class="left-show">{{ inspectionLocation[0].positionName }}</div>
			</template>
			<template v-else>
				<el-select v-model="inspLocationIndex" placeholder="请选择巡检位置" class="left-select" @change="changeLocation">
					<el-option v-for="(site, siteIndex) in inspectionLocation" :key="site.positionJobId" :label="site.positionName" :value="siteIndex">
					</el-option>
				</el-select>
			</template>
			<div v-for="(ite, idx) in inspectionItem" :key="idx" :style="inspItemIndex === idx && 'background:#f1f2f4'" class="left-item" @click="getInspectionDetail(idx)">
				{{ dealItemName(ite.itemName, ite.state) }}
			</div>
			<div class="nullTip" v-if="inspectionItem.length<1">
				暂无巡检项
			</div>
		</div>
		<div class="inspection-Detail-right marrt20">
			<!-- 巡检动态 -->
			<el-row>

				<el-col :span='24'>
					<div class="right-dynamic">
						<!--<div class="right-dynamic-head">
							<span class="head-line"></span>
							<span class="head-text">{{ $t("inspection.dynamic") }}</span>
							<span class="head-time">{{ totalTime }}</span>
						</div>
						<div class="separate-style" style="margin-top: 8px"></div>-->
						<div class="right-dynamic-step">
							<div class="step-task" v-for="(dynamic, dynamicIdx) in dynamicList" :key="dynamic.title">
								<div class="step-task-head">
									<div :style="dynamicIdx === 0 && 'opacity: 0'" class="head-line" :class="dynamicIdx > currentDynamicIdx && 'no-triggered-line'"></div>
									<div class="head-icon" :class="
                  (dynamicIdx === currentDynamicIdx && 'in-progress-icon') ||
                  (dynamicIdx > currentDynamicIdx && 'no-triggered-icon')
                "></div>
									<div :style="dynamicIdx === 4 && 'opacity: 0'" class="head-line" :class="dynamicIdx >= currentDynamicIdx && 'no-triggered-line'">
									</div>
								</div>
								<div class="step-task-main">
									<div class="main-title" :class="dynamicIdx > currentDynamicIdx && 'not-triggered-title'">
										{{ dynamic.title }}
									</div>
									<!--<div class="main-describe">{{ dynamic.describe }}</div>
									<div class="main-content">{{ dynamic.creationDate }}</div>-->
								</div>
							</div>
						</div>
						<div class="separate-style"></div>
						<div class="right-dynamic-foot">
							<div class="foot-item">
								<span class="foot-item-tile">计划名称：</span>
								<span class="foot-item-describe">
              {{ basicData.inspectionJobName }}
            </span>
							</div>
							<div class="foot-item">
								<span class="foot-item-tile">计划周期：</span>
								<span class="foot-item-describe">{{ basicData.cycleName }}</span>
							</div>
							<div class="foot-item">
								<span class="foot-item-tile">计划{{
              $t("commons.startTime") + "："
            }}</span>
								<span class="foot-item-describe">{{ basicData.startTimeStr }}</span>
							</div>
							<div class="foot-item">
								<span class="foot-item-tile">计划{{
              $t("commons.endTime") + "："
            }}</span>
								<span class="foot-item-describe">{{ basicData.endTimeStr }}</span>
							</div>
							
							<div class="foot-item">
								<span class="foot-item-tile">执行岗位：</span>
								<span class="foot-item-describe">
									<span v-for="(itn,d2) in basicData.jobDutyList" :key='d2'><span v-if="d2>0"> / </span>{{itn.dutyName}}</span>
									<span v-if="!basicData.jobDutyList||basicData.jobDutyList.length<1">-</span>
								</span>
							</div>
							<div class="foot-item">
								<span class="foot-item-tile">岗位负责人：</span>
								<span class="foot-item-describe">
									<span v-if="!basicData.dutyLeaderList||basicData.dutyLeaderList.length<1">-</span>
									<span v-for="(itn,d2) in basicData.dutyLeaderList" :key='d2'><span v-if="d2>0"> / </span>{{itn.userName}}</span>
								</span>
							</div>
							<div class="foot-item">
								<span class="foot-item-tile">部门负责人：</span>
								<span class="foot-item-describe">
									<span v-if="!basicData.deptLeaderList||basicData.deptLeaderList.length<1">-</span>
									<span v-for="(itn,d2) in basicData.deptLeaderList" :key='d2'><span v-if="d2>0"> / </span>{{itn.userName}}</span>
								</span>
							</div>
						</div>
					</div>

					<div class="right-other">
						<div class="xunCha"  v-if="nowPosiInfo.signInDate">
							<div class="title">巡查明细</div>
							<div class="_time">扫码时间：{{nowPosiInfo.signInDate||'-'}}
								<el-button v-if='nowPosiInfo.notes' class='_btn1' @click="showAllnotes=true">巡查标准</el-button>
							</div>
						</div>
						<!-- 巡检项详情 -->
						<div class="other-detail">
							<template v-if="inspectionItem[inspItemIndex]">
								<div class="title">
									{{ inspectionItem[inspItemIndex].itemName + "详情" }}
								</div>
								<div class="container">
									<div class="container-item">
										<span class="container-item-title">{{
                  $t("commons.actualStartTime") + "："
                }}</span>
										<span class="container-item-disribe">{{
                  basicData.actualStartDate
                }}</span>
									</div>
									<div class="container-item">
										<span class="container-item-title">{{
                  $t("commons.actualEndTime") + "："
                }}</span>
										<span class="container-item-disribe">{{
                  basicData.actualFinishDate
                }}</span>
									</div>
									<div class="container-item">
										<span class="container-item-title">{{
                  $t("inspection.template.recodeResult") + "："
                }}</span>
										<span class="container-item-disribe" :class="
                    (basicData.resultItemName === '不合格' &&
                      'unqualified-style') ||
                    (basicData.resultItemName === '合格' && 'qualified-style')
                  ">{{ basicData.resultItemName }}</span
                >
              </div>
              <div class="container-item">
                <span class="container-item-title">{{
                  $t("commons.costTime") + "："
                }}</span>
										<span class="container-item-disribe">{{
                  basicData.costTime
                }}</span>
									</div>
								</div>
							</template>
						</div>
						<!-- 检查项 -->
						<div class="other-censor" v-show="checkItemList.length">
							<div class="title">{{ $t("inspection.template.checkItem") }}</div>
							<div class="table-model">
								<div class="table-model-item" v-for="(checkItem,dex) in checkItemList" :key="dex">
									<el-tooltip :content="checkItem.checkItemJobName||''" >
      									<div class="item-caption">
											<span class="common-style ellipsis-style">{{checkItem.checkItemJobName}}</span>
										</div>
    								</el-tooltip>
									<div class="item-content">
										<span class="common-style ellipsis-style" :style="checkItem.style">{{ checkItem.checkItemValue }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <!-- 选择项 -->
        <div class="other-choose" v-show="selectItemList.length">
          <div class="title">{{ $t("inspection.template.chooseItem") }}</div>
          <div class="table-model">
            <div
              class="table-model-item"
              v-for="selectItem in selectItemList"
              :key="selectItem.selectItemJobName"
            >
            <el-tooltip :content="selectItem.selectItemJobName" >
				
				<div class="item-caption">
                	<span class="common-style ellipsis-style">{{selectItem.selectItemJobName}}</span>
				</div>
				
			</el-tooltip>
            
              
									<div class="item-content">
										<template v-if="selectItem.isTooltip">
											<el-popover placement="top-start" width="500" trigger="hover" popper-class="up-popover" :content="selectItem.describe">
												<span class="common-style ellipsis-style" slot="reference">{{ selectItem.describe }}</span
                    >
                  </el-popover>
                </template>
                <template v-else>
                  <span class="common-style" ref="selectDom">{{
                    selectItem.describe
                  }}</span>
										</template>
									</div>
								</div>
							</div>
						</div>
					
					<!-- 评分项 -->
					<div class="other-score" v-show="scoreItemList.length">
						<div class="title">{{ $t("inspection.template.scoreItem") }}</div>
						<div class="table-model">
							<div class="table-model-item" v-for="scoreItem in scoreItemList" :key="scoreItem.scoreItemJobName">
								<el-tooltip :content="scoreItem.scoreItemJobName" >
									<div class="item-caption">
										<span class="common-style ellipsis-style">{{scoreItem.scoreItemJobName}}</span>
									</div>
								</el-tooltip>
								
								
								<div class="item-content">
									<span class="common-style ellipsis-style">{{
                  scoreItem.score === 0 ? noData : scoreItem.score
                }}</span>
								</div>
							</div>
						</div>
					</div>
					<!-- 抄录项 -->
					<div class="other-copy" v-show="copyItemList.length">
						<div class="title">{{ $t("inspection.template.copyItem") }}</div>
						<div class="table-model">
							<div class="table-model-item" v-for="copyItem in copyItemList" :key="copyItem.copyItemJobName">
								<el-tooltip :content="copyItem.copyItemJobName" >
									<div class="item-caption">
										<span class="common-style ellipsis-style">{{copyItem.copyItemJobName}}</span>
									</div>
								</el-tooltip>
								
								<div class="item-content">
									<template v-if="copyItem.isTooltip">
										<el-popover placement="top-start" width="500" trigger="hover" popper-class="up-popover" :content="copyItem.copyItemValue">
											<span class="common-style ellipsis-style" slot="reference">{{ copyItem.copyItemValue }}</span
                    >
                  </el-popover>
                </template>
                <template v-else>
                  <span class="common-style" ref="copyDom">{{
                    copyItem.copyItemValue
                  }}</span>
									</template>
								</div>
							</div>
						</div>
					</div>
					<el-dialog title="巡查标准" :visible.sync="showAllnotes" width="600px" >
					  	<div style="padding-bottom: 20px;">
					  		<el-input v-model="nowPosiInfo.notes"
							  	type="textarea" maxlength="600" :readonly='true'
								show-word-limit :rows="30" placeholder="暂无"></el-input>
							<div style="padding-top: 12px;text-align: right;">
								<el-button type="primary" @click='showAllnotes=false'>确定</el-button>
							</div>
					  </div>
					</el-dialog>
					<!-- 现场照片/视频 -->
					<div class="other-spot" v-show="photoIdList.length">
						<div class="title">{{ $t("inspection.template.liveSituation") }}</div>
						<div class="other-spot-img">
							<el-form label-width="0">
								<el-row v-if="photoIdList && photoIdList.length > 0">
									<el-col :span="24">
										<div class="pdl32 ">
											<viewer :images="photoIdList">
												<template v-for="(src, index) in photoIdList" class="img-box">
													<img v-if="
                          !src.fileType ||
                          (src.fileType && src.fileType.includes('image'))
                        " class="itImg" :src="getImgSrc(src.fileId)" :key="index" />
													<span class="itImg itVedio" v-if="src.fileType.includes('video')" @click.stop="toPrevVedio(src)">
                								<img class="vimg" :src="getImgSrc(src.thumbnailId)" />
                								<i  class="playIcon el-icon-video-play" ></i>
                    						</span>
												</template>

											</viewer>
										</div>
									</el-col>
								</el-row>
							</el-form>
						</div>
					</div>
					<div v-if="htmlDatas.notes">
						<div class="title">备注</div>
						<div>
							<el-input
								type="textarea" 
  								rows="auto"
  								readonly
  								autosize
								v-model="htmlDatas.notes">
							</el-input>
							
						</div>
					</div>
					
					
					<div class="mapWarp">
						<div class="title">计划路线</div>
						<div class="tipsBox" v-if="showTip">
							<!--<i class="el-icon-warning-outline"></i>-->
							地图若无任何线路显示，可前往【空间管理】页面，完善位置经纬度信息
							
							<i class="el-icon-circle-close close"  @click="showTip=false"></i>
						</div>
						<waylineMap v-if='!initLoad' :lineArrs='lineArrs'></waylineMap>
					</div>
					</div>
				</el-col>
				<!--<el-col :span='7' class='pdl12'>
					<div  class='bgwhite'>
						<div class="xj_warp">
							<span class="xj_status">巡检动态</span>
							<span class="xj_time" v-if='totalTime'>{{totalTime}}</span>
						</div>
						<el-timeline >
						    <el-timeline-item
						      v-for="(it,dex) in patrolStatus" :key="dex" color='#409eff'
						      :timestamp="it.creationDateStr">
						      <div class="l_name">{{it.userName}} {{it.operationName}}</div>
						      <div class="l_tips">{{it.explainCard}}</div>
						    </el-timeline-item>
					  	</el-timeline>
						
						
					</div>
					
					
				</el-col>-->
			</el-row>

		</div>
		<div  class='bgwhite'>
			<div class="xj_warp">
				<span class="xj_status">任务动态</span>
				<span class="xj_time" v-if='totalTime'>{{totalTime}}</span>
			</div>
			<el-timeline >
			    <el-timeline-item
			      v-for="(it,dex) in patrolStatus" :key="dex" color='#409eff'
			      :timestamp="it.creationDateStr">
			      <div class="l_name">{{it.userName}} {{it.operationName}}</div>
			      <div class="l_tips">{{it.explainCard}}</div>
			    </el-timeline-item>
		  	</el-timeline>
			
			
		</div>
	</div>
	</div>
</template>

<script>
import {
  getInspectionQueryDetail,
  detailsInitialization,
  getInspectionItem,
  getItemDetail,
} from "@/api/business/inspection/tenant/query";
import { difference } from "@/utils/dateCalc";
import { envInfo } from "@/constants/envInfo";
import moment from "moment";
import waylineMap from "./waylineMap.vue";
import { getVedioSrc } from "@/utils/download.js";
export default {
  name: "InspectionDetail",
  components: {waylineMap},
  props: {
    inspectionJobId: {
      type: Number|String,
      required: true,
    },
  },
  data() {
    return {
      htmlDatas: {
        notes: "",
      },
      showTip:true,
      /* 巡检基本信息 */
      basicData: {
        // 巡检计划名称
        inspectionJobName: "",
        // 巡检周期名称
        cycleName: "",
        // 开始时间
        startTimeStr: "",
        // 结束时间
        endTimeStr: "",
        // 实际开始时间
        actualStartDate: "",
        // 实际完成时间
        actualFinishDate: "",
        // 耗时
        costTime: "",
        // 记录结果
        resultItemName: "",
      },
      // 巡检动态
      dynamicList: [
        {
          title: "生成任务",
          key: "create",
          describe: "",
          creationDate: "",
        },
        {
          title: "待领取",
        },
        {
          title: "待执行",
          key: "receive",
          describeEnd: "领取任务",
          describe: "",
          creationDate: "",
        },
        {
          title: "执行中",
          key: "start_implement",
          describeEnd: "开始执行",
          describe: "",
          creationDate: "",
        },
        {
          title: "已完成",
          key: "complete",
          describeEnd: "完成任务",
          describe: "",
          creationDate: "",
        },
      ],
      //存储计划路线所需要的经纬度
      lineArrs: [],
      // 巡检位置
      inspectionLocation: [],
      // 巡检项
      inspectionItem: [],
      // 检查项
      checkItemList: [],
      // 选择项
      selectItemList: [],
      // 评分项
      scoreItemList: [],
      // 抄录项
      copyItemList: [],
      // 现场照片/视频
      photoIdList: [],
      // 当前巡检位置
      inspLocationIndex: 0,
      // 当前巡检项
      inspItemIndex: 0,
      // 当前巡检动态索引
      currentDynamicIdx: "",
      tempIndex: "",
      // 暂无数据
      noData: "- -",
      // 初始化加载动画
      initLoad: true,
      // 执行人
      executor: "",
      // 总耗时
      totalTime: "",
      // 后台返回的巡检动态
      patrolStatus: [],
      //巡检动态总耗时长
      consumingTime: "",
      sArrs: ["待执行", "执行中", "已完成", "已关单"],
      urlPositionJobId:'',
      urlItemJobId:'',
      isXunCha:false,//是否巡查
      showAllnotes:false,
      nowPosiInfo:{//当前巡检点/位置的具体信息
      	signInDate:'',
      	notes:'',
      },
    };
  },
  created() {
    this.initPage();
  },
  mounted() {
  	
  	
  	
  },
  methods: {
  	async initUrlDatas(){
  			let findDex=0;
  			if(this.urlPositionJobId){
  				this.inspectionLocation.map((its,dex)=>{
  					if(its.positionJobId==this.urlPositionJobId){
//						this.inspLocationIndex=dex;
  						findDex=dex;
  					}
  				})
  			}
  			
  		
  		
  	},
    // 初始化页面
    initPage() {
    	const { positionJobId,itemJobId} = this.$route.query;
    	if(positionJobId){
    		this.urlPositionJobId=positionJobId;
    	}
    	if(itemJobId){
    		this.urlItemJobId=itemJobId;
    	}
      const detailTsk = getInspectionQueryDetail({
        inspectionJobId: this.inspectionJobId,
      }).then((res) => {
        return res;
      });
      const otherTask = detailsInitialization({
        inspectionJobId: this.inspectionJobId,
      }).then((res) => {
      	if(res.inspectionPositionJobVO){
      		//代表是巡查  没有巡检项
      		this.isXunCha=true;
      	}else{
      		this.isXunCha=false;
      	}
        return res;
      });
      Promise.all([detailTsk, otherTask])
        .then((res) => {
        	console.log('初始化页面',JSON.parse(JSON.stringify(res)))
    	  this.basicData={... res[0]}
          this.basicData.inspectionJobName = res[0].inspectionJobName || "";
          this.basicData.cycleName = res[0].cycleName || "";
          this.basicData.startTimeStr =
            res[0].startTimeStr.replace(/-/g, "/") || "";
          this.basicData.endTimeStr =
            res[0].endTimeStr.replace(/-/g, "/") || "";
          /*
           * 巡检动态
           * 1：待领取
           * 2：待执行
           * 3：执行中
           * 4：已完成
           * 5：已关单
           */
          this.currentDynamicIdx = res[0].status;
          const currentStateDatas = {};
          res[0].dynamicList.forEach((item) => {
            currentStateDatas[item.operationType] = item;
          });
          let len = res[0].dynamicList.length;
          if (len > 0) {
            let Arrs1 = JSON.parse(JSON.stringify(res[0].dynamicList));
            Arrs1.map((item) => {
              if (
                item.operationType == "closed" ||
                item.operationType === "complete"
              ) {
                const start = moment(Arrs1[len - 1].creationDate);
                const end = moment(item.creationDate);
                const { _data: result } = moment.duration(end.diff(start));
                this.totalTime =
                  "（总耗时：" +
                  result.days +
                  "天" +
                  result.hours +
                  "时" +
                  result.minutes +
                  "分" +
                  result.seconds +
                  "秒）";
              }
            });
            this.patrolStatus = Arrs1;
          }

          this.dynamicList.forEach((item) => {
            if (item.key && currentStateDatas[item.key]) {
              item.describe = currentStateDatas[item.key].userName;
              item.creationDate = currentStateDatas[
                item.key
              ].creationDateStr.replace(/-/g, "/");
              item.describeEnd && (item.describe += " " + item.describeEnd);
            }
            //							if(item.key === "complete" && item.creationDate) {
            //								const start = moment(this.dynamicList[0].creationDate);
            //								const end = moment(item.creationDate);
            //								const {
            //									_data: result
            //								} = moment.duration(end.diff(start));
            //								this.totalTime =
            //									"（总耗时：" +
            //									result.days +
            //									"天" +
            //									result.hours +
            //									"时" +
            //									result.minutes +
            //									"分" +
            //									result.seconds +
            //									"秒）";
            //							}
          });
          if (this.currentDynamicIdx == 5) {
            console.log(55555555555);
            this.dynamicList[4].title = "已关单";
          }
          //						console.log('---++++----', JSON.parse(JSON.stringify(this.dynamicList)))
          // 巡检位置
          
          this.lineArrs=res[1].positionList;
          res[1].positionList.forEach((site) => {
            this.inspectionLocation.push({
              positionName: site.positionName,
              positionJobId: site.positionJobId,
              ...site,
            });
          });
  			this.nowPosiInfo={...this.inspectionLocation[this.inspLocationIndex]};
  			console.log('巡检点详情',this.nowPosiInfo)
          	this.initUrlDatas();
          // 巡检项
          res[1].itemList.forEach((ele) => {
            let state = "";
            //							console.log('-sArrs-', sArrs[+ele.status])
            //							switch(ele.status) {
            //								case 0:
            //									state = "待执行";
            //									break;
            //								case 1:
            //									state = "执行中";
            //									break;
            //								case 2:
            //									state = "已完成";
            //								case 3:
            //									state = "已关单1";
            //									break;
            //							}
            this.inspectionItem.push({
              itemJobId: ele.itemJobId,
              itemName: ele.itemName,
              state: this.sArrs[+ele.status],
            });
          });
          console.log(
            "---+已关单1++----",
            JSON.parse(JSON.stringify(res[1].itemList))
          );
          if(this.isXunCha){
          	this.initLoad = false;
          }else{
          	this.dealDetail(res[1].inspectionItemJobVO);
          }
        })
        .catch(() => {
          this.initLoad = false;
        });
    },
    toPrevVedio(it) {
      let src = getVedioSrc(it.fileId);
      //		http://localhost:8080/saascloud/saas/archive/param/archive/download?dlType=DefaultDownload&fi=6584
      this.$store.commit("setVedioSrc", src);
    },
    // 获取巡检项
    changeLocation(index,itJobId) {
//  	return new Promise((resolve,reject) => {})
      const current = index;
      this.inspLocationIndex = current;
      this.nowPosiInfo={...this.inspectionLocation[this.inspLocationIndex]};
      getInspectionItem({
        positionJobId: this.inspectionLocation[index].positionJobId,
      }).then((res) => {
      	console.log('positionOne',res)
        this.inspectionItem = [];
        
        if(res.isPhotograph){
//      	 this.dealDetail(res);
        }
        
        res.itemList.forEach((ele,dex) => {
          //						let state = "";
          //						switch(ele.status) {
          //							case 0:
          //								state = "待执行";
          //								break;
          //							case 1:
          //								state = "执行中";
          //								break;
          //							case 2:
          //								state = "已完成";
          //							case 3:
          //								state = "已关单2";
          //								break;
          //						}
          this.inspectionItem.push({
            itemJobId: ele.itemJobId,
            itemName: ele.itemName,
            state: this.sArrs[+ele.status],
          });
        });
        console.log(
          "---+已关单2++----",
          JSON.parse(JSON.stringify(this.inspectionItem))
        );
        if(this.inspectionItem.length>0){
        	this.getInspectionDetail(0);
        }else{
        	 this.dealDetail(res);
        }
        
      });
    },
    // 获取巡检项详情
    getInspectionDetail(index) {
      const current = index;
      this.inspItemIndex = current;
      getItemDetail({
        itemJobId: this.inspectionItem[current].itemJobId,
      }).then((res) => {
        this.dealDetail(res);
      });
    },
    // 处理巡检项
    dealDetail(data) {
      this.htmlDatas = { ...data };
      this.checkItemList = [];
      this.selectItemList = [];
      this.scoreItemList = [];
      this.copyItemList = [];
      this.photoIdList = [];
      this.basicData.resultItemName = data.resultItemName || this.noData;
      this.basicData.actualStartDate = data.startDate
        ? moment(data.startDate).format("YYYY/MM/DD HH:mm:ss")
        : this.noData;
      this.basicData.actualFinishDate = data.endDate
        ? moment(data.endDate).format("YYYY/MM/DD HH:mm:ss")
        : this.noData;
      this.basicData.costTime =
        data.startDate && data.endDate
          ? difference(data.startDate, data.endDate)
          : this.noData;
      // 检查项
      data.checkItemList=data.checkItemList||[];
      data.checkItemList.forEach((ele) => {
        let name = "";
        let style = "";
        switch (ele.status) {
          case 0:
            name = this.noData;
            break;
          case 1:
            name = "√";
            break;
          case 2:
            name = "×";
            style = "color:#E02020";
            break;
          default:
            console.log("err");
        }
        this.checkItemList.push({
          checkItemJobName: ele.checkItemJobName,
          checkItemValue: name,
          style,
          isTooltip: false,
        });
      });
      // 选择项
      data.selectItemList=data.selectItemList||[];
      data.selectItemList.forEach((ele) => {
        let describe = "";
        let result = [];
        result = ele.selectSonItemJobList.map((item) =>
          item.checked === "true" ? item.describe : ""
        );
        result = result.filter((item) => item);
        describe = result.join("；");
        this.selectItemList.push({
          selectItemJobName: ele.selectItemJobName,
          describe: describe || this.noData,
          isTooltip: false,
        });
      });
      // 抄录项
      data.copyItemList= data.copyItemList||[];
      data.copyItemList.forEach((ele) => {
        const unit = ele.copyItemUnitName ? ele.copyItemUnitName : "";
        this.copyItemList.push({
          copyItemJobName: ele.copyItemJobName,
          copyItemValue: ele.copyItemValue
            ? ele.copyItemValue + unit
            : this.noData,
          isTooltip: false,
        });
      });
      // 评分项
      data.scoreItemList=  data.scoreItemList||[];
      data.scoreItemList.forEach((ele) => {
        this.scoreItemList.push({
          scoreItemJobName: ele.scoreItemJobName,
          score: ele.score,
          isTooltip: false,
        });
      });
      // 现场照片/视频
      this.photoIdList = data.inspectionResource || [];
      this.$nextTick(() => {
        if (this.$refs.selectDom && this.$refs.selectDom.length) {
          this.$refs.selectDom.forEach((i, idx) => {
            i.offsetHeight > 43 && (this.selectItemList[idx].isTooltip = true);
          });
        }
        if (this.$refs.copyDom && this.$refs.copyDom.length) {
          this.$refs.copyDom.forEach((i, idx) => {
            i.offsetHeight > 43 && (this.copyItemList[idx].isTooltip = true);
          });
        }
      });
      this.initLoad = false;
    },
    getImgSrc(id) {
      return (
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" +
        id
      );
    },
    // 处理巡检项名称
    dealItemName(name, state) {
      const newState = this.currentDynamicIdx <= 1 ? "未领取" : state;
      const length = name.length;
      let itemName = name + "(" + newState + ")";

      if (itemName.replace(/[\u0391-\uFFE5]/g, "aa").length > 30) {
        for (let i = 1; ; i++) {
          itemName = name.substring(0, length - i) + "...(" + newState + ")";
          if (itemName.replace(/[\u0391-\uFFE5]/g, "aa").length <= 30) {
            return itemName;
          }
        }
      }
      return itemName;
    },
  },
};
</script>

<style lang="scss">
.up-popover {
  background: #000;
  color: #fff;
}

.up-popover[x-placement^="left"] .popper__arrow::after {
  border-left-color: #000 !important;
}

.up-popover[x-placement^="right"] .popper__arrow::after {
  border-right-color: #000 !important;
}

.up-popover[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #000 !important;
}

.up-popover[x-placement^="top"] .popper__arrow::after {
  border-top-color: #000 !important;
}
</style>

<style lang="scss" scoped>
@import "../inspectionDetail/inspectionDetail.scss";
.itImg,
.itVedio {
  width: 108px;
  height: 108px;
  margin: 6px;
  vertical-align: text-top;
  display: inline-block;
  border-radius: 3px;
}

.itVedio {
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: 80px;
  border: 1px solid #cccccc;
  .vimg {
    width: 100%;
    height: 100%;
  }
  .playIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 48px;
    margin-left: -24px;
    margin-top: -24px;
    color: white;
  }
}
.mapWarp{
	.tipsBox{
		text-align: center;
		line-height: 48px;
		margin-bottom: 16px;
		background: rgba(255, 246, 217, 1);
		color: #949494;
		position: relative;
		.close{
			position: absolute;
			right: 6px;
			top: 16px;
		}
	}
}
.pdl12 {
  padding-left: 12px;
  height: 100vh;
  
}
.marrt20{
	margin-right: 20px;
}
.nullTip{
	font-size: 12px;
	color: #CCCCCC;
	text-align: center;
	padding-top: 24px;
}
.bgwhite {
	width: 358px;
    margin-top: 20px;
    background: white;
    border-radius: 3px;
    padding: 20px;
    overflow-y: auto;
    min-height: 100%;
    max-height: 120%;
    .l_name {
      font-weight: bold;
    }
    .l_tips {
      color: #999999;
      font-size: 12px;
      margin-top: 8px;
      line-height: 16px;
    }
    .xj_warp {
      padding-bottom: 8px;
      margin-bottom: 12px;
      border-bottom: 1px solid #ccc;
    }
    .xj_status {
      font-weight: bold;
      font-size: 15px;
      margin-right: 12px;
    }
    .xj_time {
      color: #666;
      font-size: 13px;
    }
  }
.xunCha{
	
	._time{
		height: 56px;
		line-height: 56px;
		background: #fcfcfc;
		font-size: 13px;
		border-radius: 3px;
		padding: 0 12px;
		position: relative;
		._btn1{
			position: absolute;
			right: 12px;
			top: 8px;
		}
	}
	
}
</style>