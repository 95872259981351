import request from "@/utils/request";
import { envInfo } from "@/constants/envInfo";

/*
 * 加载巡检查询列表
 * */
export function getInspectionQueryPage(params) {
  return request({
    url: envInfo.bgApp.inspectionPath + "/tenant/inspection/job/page",
    method: "get",
    params,
  });
}

/*
 * 根据部门id查询岗位
 * */
export function getDutyName(params) {
  return request({
    url: envInfo.bgApp.organizationPath + "/tenant/duty/list",
    method: "get",
    params,
  });
}

/*
 * 查询巡检计划详情
 * */
export function getInspectionQueryDetail(params) {
  return request({
    url: envInfo.bgApp.inspectionPath + "/tenant/inspection/job/details",
    method: "get",
    params,
  });
}

//导出巡检查询
export function exportInspection(params) {
  return request({
    url: envInfo.bgApp.inspectionPath + "/tenant/inspection/job/export",
    method: "get",
    params,
  });
}

/*
 * 巡检详情初始化
 * */
export function detailsInitialization(params) {
  return request({
    url:
      envInfo.bgApp.inspectionPath +
      "/tenant/inspection/job/detailsInitialization",
    method: "get",
    params,
  });
}

/*
 * 根据巡检位置查询巡检项
 */
export function getInspectionItem(params) {
  return request({
    url: envInfo.bgApp.inspectionPath + "/tenant/inspection/job/positionOne",
    method: "get",
    params,
  });
}

/*
 * 根据巡检查询数据
 */
export function getItemDetail(params) {
  return request({
    url: envInfo.bgApp.inspectionPath + "/tenant/inspection/job/itemDetails",
    method: "get",
    params,
  });
}

/**
查询巡检计划
Request URL:saascloud/saas/inspection/tenant/inspection/plan/findInspectionPlan
Request Method：GET
Param：
companyId(Long)：公司id
projectId(Long)：项目名id
inspectionPlanName(String)：巡检计划名称
 */
export function findInspectionPlan(params) {
  return request({
    url:
      envInfo.bgApp.inspectionPath +
      "/tenant/inspection/plan/findInspectionPlan",
    method: "get",
    params,
  });
}

/**
查询巡检计划
Request URL:saascloud/saas/inspection/tenant/inspection/reportForm/page
Request Method：GET
Param：
inspectionPlanId(Long，必填)：巡检计划id
itemPlanId(Long，必填)：巡检项id
startTime(Date)：开始时间
endTime(Date)：结束时间
rowCount(Integer，必填)：每页数据量
current(Integer，必填)：当前页
 */
export function findReportForm(params) {
  return request({
    url: envInfo.bgApp.inspectionPath + "/tenant/inspection/reportForm/page",
    method: "get",
    params,
  });
}

/**
导出巡检报表
Request URL:saascloud/saas/inspection/tenant/inspection/reportForm/export
Request Method：GET
Param：
inspectionPlanId(Long，必填)：巡检计划id
itemPlanId(Long，必填)：巡检项id
startTime(Date)：开始时间
endTime(Date)：结束时间
 */
export function exportReportForm(params) {
  return request({
    url: envInfo.bgApp.inspectionPath + "/tenant/inspection/reportForm/export",
    method: "get",
    params,
  });
}
