<template>
	<div id="gdMap" style="height:500px;">
		<!--百度地图-巡检路线展示（计划路线-->
	</div>
</template>

<script>
	var x_PI = 3.14159265358979324 * 3000.0 / 180.0;
	export default {
		props:{
			lineArrs:{
				type:Array,
				default:[]
			}
		},
		data() {
			return {
				gMap: null,
				defaultPoint: {
					lng: 114.079427,
					lat: 22.71426,
				},
				pLineArr:[
					{name:'5栋',lng:114.078278,lat:22.713962},
					{name:'10栋',lng:114.078314,lat:22.712136},
					{name:'11栋',lng:114.081449,lat:22.714103},
//					{name:'9栋',lng:114.072498,lat:22.708032},
//					{name:'8栋',lng:114.072017,lat:22.707636},
				],
				trackCanvasLayer: null, //轨迹图层
				startCanvasLayer: null, //轨迹起始点图层
				canvasLayerRunning: null, //轨迹播放时的图层
				moveCanvasLayer: null, //轨迹移动添加点图层
			}
		},
		components: {},
		created() {},
		mounted() {
			this.initBaiduMap();
		},
		methods: {
			
		bd09togcj02(bd_lon, bd_lat) {
			var bd_lon = +bd_lon;
			var bd_lat = +bd_lat;
			var x = bd_lon - 0.0065;
			var y = bd_lat - 0.006;
			var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_PI);
			var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_PI);
			var gg_lng = z * Math.cos(theta);
			var gg_lat = z * Math.sin(theta);
			return [gg_lng, gg_lat]
		},
			
			initBaiduMap() {
				//初始化百度地图
				let that=this;
				var mp = new AMap.Map('gdMap', {
	              	resizeEnable: true,
	              	zoom: 17,
//	              	center: [that.defaultPoint.lng, that.defaultPoint.lat]
		        });
	          	var path1 = [];
	          	
	          	console.log(this.lineArrs)
	          	let marker;
	          	this.lineArrs.map((it,i)=>{
	          		if(it.longitude&&it.latitude){
	          			let itp=this.bd09togcj02(it.longitude,it.latitude)
          			 	if(!marker){
          			 		mp.setCenter([itp.longitude, itp.latitude])
          			 		marker = new AMap.Marker({position: itp,});
          			 	}
	          			path1.push(itp)
	          		}
	          	})
	          	if(marker){
	          		marker.setMap(mp);
	          	}
		        var oldLine = new AMap.Polyline({
		          path:path1,
		          strokeWeight:6,
		          strokeOpacity:0.9,
		          strokeColor:'#225bff',
		          showDir:true,
		        })
		        mp.add(oldLine);
		        mp.setFitView();
		        this.gMap=mp;
//		        let graspRoad= new AMap.GraspRoad()
//		        graspRoad.driving(pathParam,function(error,result){
//		          if(!error){
//		            var path2 = [];
//		            var newPath = result.data.points;
//		            for(var i =0;i<newPath.length;i+=1){
//		              path2.push([newPath[i].x,newPath[i].y])
//		            }
//		            var newLine = new AMap.Polyline({
//		              path:path2,
//		              strokeWeight:8,
//		              strokeOpacity:0.8,
//		              strokeColor:'#0091ea',
//		              showDir:true
//		            })
//		            map.add(newLine)
//		            map.setFitView()
//		          }
//		        })
			},

		},
		updated() {},
		beforeDestroy() {},
	}
</script>

<style>

</style>