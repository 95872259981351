var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.initLoad,
          expression: "initLoad",
        },
      ],
      staticClass: "inspection-Detail",
    },
    [
      _c(
        "div",
        { staticClass: "inspection-Detail-left" },
        [
          _vm.inspectionLocation.length === 1
            ? [
                _c("div", { staticClass: "left-show" }, [
                  _vm._v(_vm._s(_vm.inspectionLocation[0].positionName)),
                ]),
              ]
            : [
                _c(
                  "el-select",
                  {
                    staticClass: "left-select",
                    attrs: { placeholder: "请选择巡检位置" },
                    on: { change: _vm.changeLocation },
                    model: {
                      value: _vm.inspLocationIndex,
                      callback: function ($$v) {
                        _vm.inspLocationIndex = $$v
                      },
                      expression: "inspLocationIndex",
                    },
                  },
                  _vm._l(_vm.inspectionLocation, function (site, siteIndex) {
                    return _c("el-option", {
                      key: site.positionJobId,
                      attrs: { label: site.positionName, value: siteIndex },
                    })
                  }),
                  1
                ),
              ],
          _vm._l(_vm.inspectionItem, function (ite, idx) {
            return _c(
              "div",
              {
                key: idx,
                staticClass: "left-item",
                style: _vm.inspItemIndex === idx && "background:#f1f2f4",
                on: {
                  click: function ($event) {
                    return _vm.getInspectionDetail(idx)
                  },
                },
              },
              [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.dealItemName(ite.itemName, ite.state)) +
                    "\n\t\t\t"
                ),
              ]
            )
          }),
          _vm.inspectionItem.length < 1
            ? _c("div", { staticClass: "nullTip" }, [
                _vm._v("\n\t\t\t\t暂无巡检项\n\t\t\t"),
              ])
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "inspection-Detail-right marrt20" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "right-dynamic" }, [
                  _c(
                    "div",
                    { staticClass: "right-dynamic-step" },
                    _vm._l(_vm.dynamicList, function (dynamic, dynamicIdx) {
                      return _c(
                        "div",
                        { key: dynamic.title, staticClass: "step-task" },
                        [
                          _c("div", { staticClass: "step-task-head" }, [
                            _c("div", {
                              staticClass: "head-line",
                              class:
                                dynamicIdx > _vm.currentDynamicIdx &&
                                "no-triggered-line",
                              style: dynamicIdx === 0 && "opacity: 0",
                            }),
                            _c("div", {
                              staticClass: "head-icon",
                              class:
                                (dynamicIdx === _vm.currentDynamicIdx &&
                                  "in-progress-icon") ||
                                (dynamicIdx > _vm.currentDynamicIdx &&
                                  "no-triggered-icon"),
                            }),
                            _c("div", {
                              staticClass: "head-line",
                              class:
                                dynamicIdx >= _vm.currentDynamicIdx &&
                                "no-triggered-line",
                              style: dynamicIdx === 4 && "opacity: 0",
                            }),
                          ]),
                          _c("div", { staticClass: "step-task-main" }, [
                            _c(
                              "div",
                              {
                                staticClass: "main-title",
                                class:
                                  dynamicIdx > _vm.currentDynamicIdx &&
                                  "not-triggered-title",
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(dynamic.title) +
                                    "\n\t\t\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "separate-style" }),
                  _c("div", { staticClass: "right-dynamic-foot" }, [
                    _c("div", { staticClass: "foot-item" }, [
                      _c("span", { staticClass: "foot-item-tile" }, [
                        _vm._v("计划名称："),
                      ]),
                      _c("span", { staticClass: "foot-item-describe" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.basicData.inspectionJobName) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "foot-item" }, [
                      _c("span", { staticClass: "foot-item-tile" }, [
                        _vm._v("计划周期："),
                      ]),
                      _c("span", { staticClass: "foot-item-describe" }, [
                        _vm._v(_vm._s(_vm.basicData.cycleName)),
                      ]),
                    ]),
                    _c("div", { staticClass: "foot-item" }, [
                      _c("span", { staticClass: "foot-item-tile" }, [
                        _vm._v(
                          "计划" + _vm._s(_vm.$t("commons.startTime") + "：")
                        ),
                      ]),
                      _c("span", { staticClass: "foot-item-describe" }, [
                        _vm._v(_vm._s(_vm.basicData.startTimeStr)),
                      ]),
                    ]),
                    _c("div", { staticClass: "foot-item" }, [
                      _c("span", { staticClass: "foot-item-tile" }, [
                        _vm._v(
                          "计划" + _vm._s(_vm.$t("commons.endTime") + "：")
                        ),
                      ]),
                      _c("span", { staticClass: "foot-item-describe" }, [
                        _vm._v(_vm._s(_vm.basicData.endTimeStr)),
                      ]),
                    ]),
                    _c("div", { staticClass: "foot-item" }, [
                      _c("span", { staticClass: "foot-item-tile" }, [
                        _vm._v("执行岗位："),
                      ]),
                      _c(
                        "span",
                        { staticClass: "foot-item-describe" },
                        [
                          _vm._l(_vm.basicData.jobDutyList, function (itn, d2) {
                            return _c("span", { key: d2 }, [
                              d2 > 0 ? _c("span", [_vm._v(" / ")]) : _vm._e(),
                              _vm._v(_vm._s(itn.dutyName)),
                            ])
                          }),
                          !_vm.basicData.jobDutyList ||
                          _vm.basicData.jobDutyList.length < 1
                            ? _c("span", [_vm._v("-")])
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                    _c("div", { staticClass: "foot-item" }, [
                      _c("span", { staticClass: "foot-item-tile" }, [
                        _vm._v("岗位负责人："),
                      ]),
                      _c(
                        "span",
                        { staticClass: "foot-item-describe" },
                        [
                          !_vm.basicData.dutyLeaderList ||
                          _vm.basicData.dutyLeaderList.length < 1
                            ? _c("span", [_vm._v("-")])
                            : _vm._e(),
                          _vm._l(
                            _vm.basicData.dutyLeaderList,
                            function (itn, d2) {
                              return _c("span", { key: d2 }, [
                                d2 > 0 ? _c("span", [_vm._v(" / ")]) : _vm._e(),
                                _vm._v(_vm._s(itn.userName)),
                              ])
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                    _c("div", { staticClass: "foot-item" }, [
                      _c("span", { staticClass: "foot-item-tile" }, [
                        _vm._v("部门负责人："),
                      ]),
                      _c(
                        "span",
                        { staticClass: "foot-item-describe" },
                        [
                          !_vm.basicData.deptLeaderList ||
                          _vm.basicData.deptLeaderList.length < 1
                            ? _c("span", [_vm._v("-")])
                            : _vm._e(),
                          _vm._l(
                            _vm.basicData.deptLeaderList,
                            function (itn, d2) {
                              return _c("span", { key: d2 }, [
                                d2 > 0 ? _c("span", [_vm._v(" / ")]) : _vm._e(),
                                _vm._v(_vm._s(itn.userName)),
                              ])
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "right-other" },
                  [
                    _vm.nowPosiInfo.signInDate
                      ? _c("div", { staticClass: "xunCha" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v("巡查明细"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "_time" },
                            [
                              _vm._v(
                                "扫码时间：" +
                                  _vm._s(_vm.nowPosiInfo.signInDate || "-") +
                                  "\n\t\t\t\t\t\t\t\t"
                              ),
                              _vm.nowPosiInfo.notes
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass: "_btn1",
                                      on: {
                                        click: function ($event) {
                                          _vm.showAllnotes = true
                                        },
                                      },
                                    },
                                    [_vm._v("巡查标准")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "other-detail" },
                      [
                        _vm.inspectionItem[_vm.inspItemIndex]
                          ? [
                              _c("div", { staticClass: "title" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.inspectionItem[_vm.inspItemIndex]
                                        .itemName + "详情"
                                    ) +
                                    "\n\t\t\t\t\t\t\t\t"
                                ),
                              ]),
                              _c("div", { staticClass: "container" }, [
                                _c("div", { staticClass: "container-item" }, [
                                  _c(
                                    "span",
                                    { staticClass: "container-item-title" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("commons.actualStartTime") +
                                            "："
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "container-item-disribe" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.basicData.actualStartDate)
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "container-item" }, [
                                  _c(
                                    "span",
                                    { staticClass: "container-item-title" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("commons.actualEndTime") + "："
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "container-item-disribe" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.basicData.actualFinishDate)
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "container-item" }, [
                                  _c(
                                    "span",
                                    { staticClass: "container-item-title" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "inspection.template.recodeResult"
                                          ) + "："
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "container-item-disribe",
                                      class:
                                        (_vm.basicData.resultItemName ===
                                          "不合格" &&
                                          "unqualified-style") ||
                                        (_vm.basicData.resultItemName ===
                                          "合格" &&
                                          "qualified-style"),
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.basicData.resultItemName)
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("div", { staticClass: "container-item" }, [
                                  _c(
                                    "span",
                                    { staticClass: "container-item-title" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("commons.costTime") + "："
                                        )
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "container-item-disribe" },
                                    [_vm._v(_vm._s(_vm.basicData.costTime))]
                                  ),
                                ]),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.checkItemList.length,
                            expression: "checkItemList.length",
                          },
                        ],
                        staticClass: "other-censor",
                      },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            _vm._s(_vm.$t("inspection.template.checkItem"))
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "table-model" },
                          _vm._l(_vm.checkItemList, function (checkItem, dex) {
                            return _c(
                              "div",
                              { key: dex, staticClass: "table-model-item" },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: checkItem.checkItemJobName || "",
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "item-caption" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "common-style ellipsis-style",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(checkItem.checkItemJobName)
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "item-content" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "common-style ellipsis-style",
                                      style: checkItem.style,
                                    },
                                    [_vm._v(_vm._s(checkItem.checkItemValue))]
                                  ),
                                ]),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectItemList.length,
                            expression: "selectItemList.length",
                          },
                        ],
                        staticClass: "other-choose",
                      },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            _vm._s(_vm.$t("inspection.template.chooseItem"))
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "table-model" },
                          _vm._l(_vm.selectItemList, function (selectItem) {
                            return _c(
                              "div",
                              {
                                key: selectItem.selectItemJobName,
                                staticClass: "table-model-item",
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: selectItem.selectItemJobName,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "item-caption" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "common-style ellipsis-style",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(selectItem.selectItemJobName)
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "item-content" },
                                  [
                                    selectItem.isTooltip
                                      ? [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "top-start",
                                                width: "500",
                                                trigger: "hover",
                                                "popper-class": "up-popover",
                                                content: selectItem.describe,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "common-style ellipsis-style",
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(selectItem.describe)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "span",
                                            {
                                              ref: "selectDom",
                                              refInFor: true,
                                              staticClass: "common-style",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(selectItem.describe)
                                              ),
                                            ]
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.scoreItemList.length,
                            expression: "scoreItemList.length",
                          },
                        ],
                        staticClass: "other-score",
                      },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            _vm._s(_vm.$t("inspection.template.scoreItem"))
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "table-model" },
                          _vm._l(_vm.scoreItemList, function (scoreItem) {
                            return _c(
                              "div",
                              {
                                key: scoreItem.scoreItemJobName,
                                staticClass: "table-model-item",
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: scoreItem.scoreItemJobName,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "item-caption" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "common-style ellipsis-style",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(scoreItem.scoreItemJobName)
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("div", { staticClass: "item-content" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "common-style ellipsis-style",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          scoreItem.score === 0
                                            ? _vm.noData
                                            : scoreItem.score
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.copyItemList.length,
                            expression: "copyItemList.length",
                          },
                        ],
                        staticClass: "other-copy",
                      },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            _vm._s(_vm.$t("inspection.template.copyItem"))
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "table-model" },
                          _vm._l(_vm.copyItemList, function (copyItem) {
                            return _c(
                              "div",
                              {
                                key: copyItem.copyItemJobName,
                                staticClass: "table-model-item",
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: copyItem.copyItemJobName,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "item-caption" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "common-style ellipsis-style",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(copyItem.copyItemJobName)
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "item-content" },
                                  [
                                    copyItem.isTooltip
                                      ? [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "top-start",
                                                width: "500",
                                                trigger: "hover",
                                                "popper-class": "up-popover",
                                                content: copyItem.copyItemValue,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "common-style ellipsis-style",
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      copyItem.copyItemValue
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "span",
                                            {
                                              ref: "copyDom",
                                              refInFor: true,
                                              staticClass: "common-style",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(copyItem.copyItemValue)
                                              ),
                                            ]
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          title: "巡查标准",
                          visible: _vm.showAllnotes,
                          width: "600px",
                        },
                        on: {
                          "update:visible": function ($event) {
                            _vm.showAllnotes = $event
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "padding-bottom": "20px" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                maxlength: "600",
                                readonly: true,
                                "show-word-limit": "",
                                rows: 30,
                                placeholder: "暂无",
                              },
                              model: {
                                value: _vm.nowPosiInfo.notes,
                                callback: function ($$v) {
                                  _vm.$set(_vm.nowPosiInfo, "notes", $$v)
                                },
                                expression: "nowPosiInfo.notes",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "padding-top": "12px",
                                  "text-align": "right",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showAllnotes = false
                                      },
                                    },
                                  },
                                  [_vm._v("确定")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.photoIdList.length,
                            expression: "photoIdList.length",
                          },
                        ],
                        staticClass: "other-spot",
                      },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(
                            _vm._s(_vm.$t("inspection.template.liveSituation"))
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "other-spot-img" },
                          [
                            _c(
                              "el-form",
                              { attrs: { "label-width": "0" } },
                              [
                                _vm.photoIdList && _vm.photoIdList.length > 0
                                  ? _c(
                                      "el-row",
                                      [
                                        _c("el-col", { attrs: { span: 24 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "pdl32" },
                                            [
                                              _c(
                                                "viewer",
                                                {
                                                  attrs: {
                                                    images: _vm.photoIdList,
                                                  },
                                                },
                                                [
                                                  _vm._l(
                                                    _vm.photoIdList,
                                                    function (src, index) {
                                                      return [
                                                        !src.fileType ||
                                                        (src.fileType &&
                                                          src.fileType.includes(
                                                            "image"
                                                          ))
                                                          ? _c("img", {
                                                              key: index,
                                                              staticClass:
                                                                "itImg",
                                                              attrs: {
                                                                src: _vm.getImgSrc(
                                                                  src.fileId
                                                                ),
                                                              },
                                                            })
                                                          : _vm._e(),
                                                        src.fileType.includes(
                                                          "video"
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "itImg itVedio",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      return _vm.toPrevVedio(
                                                                        src
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("img", {
                                                                  staticClass:
                                                                    "vimg",
                                                                  attrs: {
                                                                    src: _vm.getImgSrc(
                                                                      src.thumbnailId
                                                                    ),
                                                                  },
                                                                }),
                                                                _c("i", {
                                                                  staticClass:
                                                                    "playIcon el-icon-video-play",
                                                                }),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.htmlDatas.notes
                      ? _c("div", [
                          _c("div", { staticClass: "title" }, [_vm._v("备注")]),
                          _c(
                            "div",
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  rows: "auto",
                                  readonly: "",
                                  autosize: "",
                                },
                                model: {
                                  value: _vm.htmlDatas.notes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.htmlDatas, "notes", $$v)
                                  },
                                  expression: "htmlDatas.notes",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mapWarp" },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("计划路线"),
                        ]),
                        _vm.showTip
                          ? _c("div", { staticClass: "tipsBox" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t地图若无任何线路显示，可前往【空间管理】页面，完善位置经纬度信息\n\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t"
                              ),
                              _c("i", {
                                staticClass: "el-icon-circle-close close",
                                on: {
                                  click: function ($event) {
                                    _vm.showTip = false
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                        !_vm.initLoad
                          ? _c("waylineMap", {
                              attrs: { lineArrs: _vm.lineArrs },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bgwhite" },
        [
          _c("div", { staticClass: "xj_warp" }, [
            _c("span", { staticClass: "xj_status" }, [_vm._v("任务动态")]),
            _vm.totalTime
              ? _c("span", { staticClass: "xj_time" }, [
                  _vm._v(_vm._s(_vm.totalTime)),
                ])
              : _vm._e(),
          ]),
          _c(
            "el-timeline",
            _vm._l(_vm.patrolStatus, function (it, dex) {
              return _c(
                "el-timeline-item",
                {
                  key: dex,
                  attrs: { color: "#409eff", timestamp: it.creationDateStr },
                },
                [
                  _c("div", { staticClass: "l_name" }, [
                    _vm._v(
                      _vm._s(it.userName) + " " + _vm._s(it.operationName)
                    ),
                  ]),
                  _c("div", { staticClass: "l_tips" }, [
                    _vm._v(_vm._s(it.explainCard)),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }